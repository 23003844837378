import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import Header from "../components/header";
import Image from "../components/image";
import ContactUs from "../components/contactUs";
import Footer from "../components/footer";
import CBGIsolateImg from "../assets/images/cbg-isolate-mobile.png";

const Strategy = ({ location }) => {
  return (
    <Fragment>
      <Helmet>
        <title>White Label CBG Isolate | CBD Isolate Wholesale Prices</title>
        <meta
          name="description"
          content="Are you looking for CBG or CBD isolates? Visit our website to get the highest quality white label CBG and CBD isolates a the best wholesale prices."
        />
        <link
          href="https://cbgwholesalers.com/cbg-isolate-wholesale"
          rel="canonical"
        />
      </Helmet>
      <main>
        <Header pathname={location.pathname} />
        <section class="hero -gold">
          <div
            class="hero-stack bg-isolate"
            style={{
              backgroundImage: `url(${CBGIsolateImg})`,
            }}
          >
            <h1
              class="hero-headline -animatedLine -py"
              style={{ maxWidth: "520px" }}
            >
              CBG Isolate
              <br />
              {/* <span>Ranking #1 in Organic search</span> */}
            </h1>
            <Image
              src={"cbgIsolate.png"}
              className="hero-innerImage-outer"
              imgClassName="hero-innerImage"
              placeholder="blurred"
            />
            <p
              class="hero-subheading -py -mt-15"
              style={{ maxWidth: "40ch", marginBottom: "-5rem" }}
            >
              CBG isolate is a must-have in your brand’s selection of products.
              While most hemp-derived products contain a small amount of THC,
              CBG isolate is purely CBG. This makes it the perfect choice for
              users who are sensitive to THC or can’t have it in their systems
              in case they are drug tested. CBG is a cannabinoid notable for its
              ability to relieve pain, nausea, and inflammation without
              producing any psychoactive effects.
            </p>
          </div>
        </section>
        <ContactUs
          // head1="Search is Everything"
          //   head3="Search is everything"
          des1="With its rapid rise in popularity, there is a growing market for products like CBG isolate. If your customers are begging you to add CBG products to your lineup, check out the wholesale options from CBG Wholesalers. You and your customers will love our affordable, high-quality products that are vetted for safety and efficacy. With unbeatable safety and quality standards, our wholesale CBG products beat out all products our competitors have to offer.<br /><br />CBG Wholesalers stands by the excellent quality of the wholesale CBG isolate we offer. With our deep knowledge of cannabinoids, we can answer any questions you may have about the bulk CBG isolate in our catalog. We only onboard brands that feature safe and effective products, so you can trust that all of our CBG isolates are held to the highest standard of quality. <br /><br />Managing bulk CBG orders from multiple wholesalers can be tricky. We make it easy to find all of the bulk CBG products you need for your business in one place, so you don’t have to deal with the hassle of ordering from different wholesalers. Whether you are a well-established brand or budding business, CBG Wholesalers is here to support you at any stage of growth."
          des2="Choose CBG Wholesalers for the widest selection of potent CBG isolates from the best brands around. With a vast selection of the finest wholesale CBG products available at the lowest prices, we are the premier purveyor of CBG isolates. Our prices and quality are unbeatable, so choose CBG Wholesalers for all of your wholesale CBG isolate needs. We make the process of
          ordering CBG isolate in bulk simple and easy, so you don’t have to worry about managing multiple orders at once."
          // head2="Evaluate Your Business"
          image="sub-banner.png"
        />
        <Footer isTopborder={true} />
      </main>
      <ToastContainer />
    </Fragment>
  );
};

export default Strategy;
